export default {
    name: 'login',
    data() {
        return {
            mobile: '', //手机号
            code: '', //验证码
            wx_code: '', //用户信息code
            wxConfig: {
                redirect_uri: 'http://airland.orange100.cn' //回调地址
            },
            timerNum: 60,
            isClick: true
        }
    },
    created() {
        document.title = "登录";
        this.getWxCode();
    },
    methods: {
        //登录
        Login() {
            let _this = this;
            if (!_this.mobile) {
                this.$notify({ type: 'warning', message: '请输入手机号', duration: 2000 });
                return
            } else if (!_this.code) {
                this.$notify({ type: 'warning', message: '请输入验证码', duration: 2000 });
                return
            }
            this.$UtilsApi.login({
                mobile: this.mobile,
                code: this.code,
                wx_code: this.wx_code,
                mall_id: localStorage.getItem('mall_id')
            }).then(res => {
                if (res.data.code == 0) {
                    localStorage.setItem('user_token', res.data.data.token);
                    this.$UtilsApi.memberInfo({}).then(user_data => {
                        if (user_data.data.code == 0) {
                            localStorage.setItem('user_id', user_data.data.data.id);
                            window.location.replace('//paradise.h5.airland100.com/#/home');
                        }
                    });
                } else {
                    if (res.data.msg) {
                        this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                    }
                }
            });
        },
        getWxCode() {
            const local = window.location.href;
            if (!this.GetQueryString('code')) {
                window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.$appId + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect'
            } else {
                this.wx_code = this.GetQueryString('code');
                //this.getOpenId(code) //把code传给后台获取用户信息
            }
        },
        GetQueryString(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
            var r = window.location.search.substr(1).match(reg);
            if (r != null) return unescape(r[2]);
            return null;
        },
        //获取验证码
        getCode() {
            if (!this.isClick) {
                this.$notify({ type: 'danger', message: '点击发送频繁，请稍后再试', duration: 2000 });
                return
            }
            if (this.timerNum != 60) {
                return
            };
            this.isClick = false;
			let form_data={
				mobile: this.mobile
			};
			if(localStorage.getItem('act_id')){
				form_data.act_id=localStorage.getItem('act_id');
			}
            this.$UtilsApi.login_sms(form_data).then(res => {
                if (res.data.code == 0) {
                    this.timerOut();
                    this.$notify({ type: 'success', message: '验证码发送成功', duration: 2000 });
                    this.isClick = true;
                } else {
                    if (res.data.msg) {
                        this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                    }
                    this.isClick = true;
                }
            });
        },
        timerOut() {
            if (this.timerNum > 0) {
                this.timerNum--;
                setTimeout(() => {
                    this.timerOut();
                }, 1000);
            } else {
                this.timerNum = 60;
            };
        }
    },
}