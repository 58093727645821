import notice from '../../../components/notice.vue' //须知
import lablePop from '../../../components/lablePop.vue' //弹窗
import { Calendar } from "@icon-park/vue"; //icon图标
import couponItem from '../../../components/couponItem.vue' //优惠券
export default {
    name: 'weeks',
    components: {
        notice,
        Calendar,
        lablePop,
        couponItem
    },
    data() {
        return {
            show: false, //日期选择器显示隐藏
            defaultData: [new Date(), 0], //默认选中时间
            infoData: {},
            num: 0,
            is_pop: false,
            pop_show: false,
            is_show: false,
            coupon_list: [],
            filter_type: 1, //筛选 0不选 1金额满减券,2次数增加劵
            select_id: null,
            is_null: false,
            is_login: null,
            select_data: null,
            is_pt: false,
            minDate: null,
            maxDate: null,
			attr_index:0
        }
    },
    created() {
        document.title = "门票详情";
        localStorage.removeItem('login_data');
        this.is_pt = this.$route.query.is_pt ? true : false;
        if (this.$route.query.mall_id) {
            localStorage.setItem('mall_id', this.$route.query.mall_id);
        };
        this.getTicketInfo();
    },
    methods: {
        gotoPay() {
            let if_mf = false;
            if (this.select_data) {
                // 0元购
                if (this.select_data.id == this.select_id && this.select_data.info.type == 1 && this.infoData.price - this.select_data.info.money <= 0) {
                    if_mf = true;
                }
            }
            if (if_mf) {
                console.log("满足0元购条件");
                this.createOrder();
            } else {
                console.log("不满足0元购条件");
                let data = {
                    type: 'weeks',
                    id: this.$route.query.id,
                    date: this.getDate(this.defaultData[0]),
                    coupon_id: this.select_id,
                };
                this.is_pt ? data.is_pt = 1 : '';
                this.is_pt ? data.group_ticket_id = this.infoData.group_cnf.id : '';
                this.$router.push({
                    name: 'info/pay',
                    query: data
                });
            }
        },
        //创建订单
        createOrder() {
            let data = {
                ticket_id: this.$route.query.id,
            };
            this.this.getDate(this.defaultData[0]) ? data.ticket_day = this.getDate(this.defaultData[0]) : '';
            this.select_id ? data.member_coupon_id = this.select_id : '';
            this.$UtilsApi.activeCheckout(data).then(res => {
                if (res.data.code == 0) {
                    this.$router.replace({
                        name: 'payState',
                        query: {
                            type: 'ok',
                            order_id: res.data.data.order_info.id,
                            price: res.data.data.order_info.price
                        }
                    });
                } else {
                    if (res.data.msg) {
                        this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                    }
                }
            });
        },
        hidePop(e) {
            this.pop_show = false;
        },
        setSelect(id) {
            this.select_id = id;
            this.select_data = null;
            this.coupon_list.forEach((item, index) => {
                if (item.id == this.select_id) {
                    this.select_data = item;
                }
            });
            console.log(this.select_data)
        },
        pop() {
            if (this.defaultData[1].getTime() < new Date(this.infoData.act_time_end).getTime()) {
                this.toPay();
            } else {
                this.is_pop = true;
            }
        },
        //获取门票详情
        getTicketInfo() {
            let _this = this;
            this.$UtilsApi.ticketInfo(this.$route.query.id, {}).then(res => {
                if (res.data.code == 0) {
                    this.infoData = res.data.data;
                    this.minDate = new Date(this.infoData.act_time_start);
                    this.maxDate = new Date(this.infoData.act_time_end);
                    console.log(this.minDate)

                    this.num = res.data.data.num;
                    this.defaultData[0] = new Date();
                    if (new Date().getTime() < this.minDate.getTime()) {
                        this.defaultData[0] = this.minDate;
                    } else if (new Date().getTime() > this.maxDate.getTime()) {
                        this.defaultData[0] = this.maxDate;
                    } else {
                        this.minDate = new Date();
                    }
                    this.defaultData[1] = new Date(this.defaultData[0].getTime() + 1000 * 60 * 60 * 24 * (this.num - 1));
                    _this.$UtilsApi.coupon_my({
                        status: 'unuse',
                        page: 0
                    }).then(new_res => {
                        _this.is_login = new_res;
                        if (new_res.data.code == 0) {
                            let arr = [];
                            _this.coupon_list = new_res.data.data.filter(item => item.info.ranges.indexOf(_this.infoData.type) != -1);
                            _this.coupon_list.forEach((item, index) => {
                                if (item.info.type == 1 && _this.infoData.price >= item.info.usage_limit && _this.infoData.price <= item.info.usage_limit_upper) {
                                    arr.push(item);
                                }
                                if (item.info.type == 2 && _this.infoData.num >= item.info.usage_limit && _this.infoData.num <= item.info.usage_limit_upper) {
                                    arr.push(item);
                                }
                            });
                            arr.length == 0 ? _this.is_null = true : '';
                            _this.coupon_list = arr;
                        }
                    });
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        },
        toPay() {
			let attr_id=this.infoData.attrs && this.infoData.attrs.length?this.infoData.attrs[this.attr_index].id:false;
			if(attr_id){
				localStorage.setItem('attr_id',attr_id)
			}
            if (this.is_login.data.code == 0) {
                if (this.coupon_list.length) {
                    this.is_show = true;
                } else {
                    this.gotoPay()
                }
            } else {
                if (this.is_login.data.code == 401) {
                    localStorage.setItem('login_data', JSON.stringify({
                        name: 'weeks',
                        id: this.$route.query.id
                    }));
                    window.location.replace('//paradise.h5.airland100.com/#/login');
                    return
                };
                this.$notify({ type: 'warning', message: this.is_login.data.message, duration: 2000 });
            }
        },
        onConfirm(date) {
            console.log(date)
            this.show = false;
            this.defaultData[0] = date;
            this.defaultData[1] = new Date(date.getTime() + 1000 * 60 * 60 * 24 * (this.num - 1));
        },
        // 根据时间戳转换日期
        getDate(timestr, type) {
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            if (timestr > new Date(this.infoData.act_time_end).getTime() && type) {
                return this.infoData.act_time_end
            }
            return year + "-" + (month < 10 ? '0' + month : month) + "-" + (date < 10 ? '0' + date : date);
        }
    },
}