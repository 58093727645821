import lablePop from '../../components/lablePop.vue' //弹窗
export default {
    name: 'refund',
    components: {
        lablePop
    },
    data() {
        return {
            phone: '',
            list: [],
            is_pop: 0,
            order_id: null,
            price: 0,
            ticket_type: 0,
            numberValue: '',
            orderInfo: null,
            isDay: 1,
            is_click: true,
            show_data: null,
            user_data: {},
            pop_show: false,
			sele_data:''
        }
    },
    created() {
        document.title = "订单查询";
        this.$UtilsApi.memberInfo({}).then(res => {
            if (res.data.code == 0) {
                this.user_data = res.data.data;
            }
        });
        if (localStorage.getItem('phone')) {
            this.phone = localStorage.getItem('phone');
            this.getList();
        }
        this.isDate(); //区分工作日
    },
    methods: {
        hidePop(e) {
            this.pop_show = false;
        },
        isDate() {
            let today = new Date();
            let dayOfWeek = today.getDay(); // 获取当前星期几，返回0-6，分别表示周日到周六
            this.isDay = dayOfWeek === 0 || dayOfWeek === 6 ? 'weekend' : 'weekday'; //1为双休日
        },
        //获取订单详情
        getOrderInfo(id) {
            this.$UtilsApi.manageInfo(id, {}).then(res => {
                if (res.data.code == 0) {
                    this.order_id = id;
                    this.orderInfo = res.data.data;
                    // this.is_click = this.orderInfo[this.isDay] == 1 && this.orderInfo.special_date.indexOf(this.getNewDate(new Date()).split('.').join('-')) == -1;
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        },
        //核销票据
        manageCheck() {
            if (!this.is_click) {
                return
            };
			if(this.orderInfo.check_option && !this.sele_data){
				this.$notify({ type: 'warning', message: '请选择核销项', duration: 2000 });
				return
			}
			let data={
                order_id: this.order_id
            }
			this.sele_data?data.check_option=this.sele_data:'';
            this.$UtilsApi.orderCheck(data).then(res => {
                if (res.data.code == 0) {
                    this.show_data = res.data.data.check_num;
                    this.setWrite('mr', this.orderInfo.ticket_title, res.data.data.mobile, res.data.data.chk_time, this.orderInfo.ticket_type, this.orderInfo,res.data.data.ticket_use_id);
                    setTimeout(() => {
                        this.show_data = null;
                    }, 2000);
                    this.getList();
                    this.orderInfo = null;
                    this.order_id = null;
					this.sele_data='';
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                    this.orderInfo = null;
                }
            })
        },
        //添加核销记录
        setWrite(type, name, mobile, chk_time, ticket_type, info,ticket_use_id ) {
            let data = {
                type: type,
                name: name,
                mobile: mobile,
                chk_time: chk_time,
                ticket_type: ticket_type,
                info: info,
				ticket_use_id:ticket_use_id
            }
            if (!localStorage.getItem('write_' + type + localStorage.getItem('user_id'))) {
                localStorage.setItem('write_' + type + localStorage.getItem('user_id'), JSON.stringify([data]));
            } else {
                let arr = JSON.parse(localStorage.getItem('write_' + type + localStorage.getItem('user_id')));
                if (arr.length < 20) {
                    arr.unshift(data);
                } else {
                    arr.pop();
                    arr.unshift(data);
                }
                localStorage.setItem('write_' + type + localStorage.getItem('user_id'), JSON.stringify(arr));
            };
        },
        close() {
            this.orderInfo = null;
        },
        validateNumber(event) {
            let value = event.target.value
                // 使用正则表达式过滤出非金额格式的字符，除了数字、小数点和负号外，其他字符全部替换为空字符串
            value = value.replace(/[^\d.-]/g, '')
                // 只保留第一个负号和第一个小数点，其他负号和小数点删除
            value = value.replace(/^\-/, '$#$').replace(/\-/g, '').replace('$#$', '-')
            value = value.replace(/^\./, '0.').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
                // 对小数点后的字符进行限制，只保留两位小数
            let pos = value.indexOf('.')
            if (pos !== -1) {
                value = value.substr(0, pos + 1) + value.substr(pos + 1, 2).replace(/[^\d]/g, '')
            }
            if (value > this.price) {
                value = this.price
            }
            this.numberValue = value;

        },
        //去订单详情
        toOrderInfo(id) {
            this.$router.push({
                name: 'adminOrderInfo',
                query: {
                    id: id
                }
            })
        },
        //退款
        tk(is) {
            if (is) {
                if (!this.numberValue) {
                    this.$notify({ type: 'warning', message: '请正确输入退款金额', duration: 2000 });
                    return
                }
                this.$UtilsApi.refund({
                    order_id: this.order_id,
                    refund_amount: this.numberValue
                }).then(res => {
                    if (res.data.code == 0) {
                        this.is_pop = 2;
                        this.getList();
                    } else {
                        this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                    }
                });
                console.log('退款')
            }
            this.numberValue = '';
            this.is_pop = 0;
        },
        pop(id) {
            this.$UtilsApi.manageInfo(id, {}).then(res => {
                if (res.data.code == 0) {
                    // 获取当前日期对象
                    // let today = new Date();

                    // // 获取时间戳
                    // let timestamp = res.data.data.ticket_time;

                    // // 将时间戳转换为日期对象
                    // let date = new Date(timestamp * 1000);

                    // // 计算今天和输入的日期之间相差的毫秒数
                    // let diffInMs = today.getTime() - date.getTime();

                    // // 将毫秒数转换为天数
                    // let diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24)) + 1;
                    // if (res.data.data.ticket_type == 2) {
                    //     this.price = (res.data.data.price / res.data.data.ticket_num * (res.data.data.ticket_num - diffInDays));
                    // } else {
                    //     this.price = (res.data.data.price / res.data.data.ticket_num * res.data.data.ticket_surplus_num);
                    // }
                    this.price = res.data.data.price;
                    this.ticket_type = res.data.data.ticket_type;
                    this.order_id = id;
                    this.is_pop = 1;
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        },
        getList() {
            if (!this.phone.length) {
                this.$notify({ type: 'warning', message: '请输入手机号', duration: 2000 });
                return
            };
            this.$UtilsApi.search({
                mobile: this.phone,
            }).then(res => {
                if (res.data.code == 0) {
                    localStorage.setItem('phone', this.phone)
                    this.list = res.data.data;
                    console.log(res.data.data)
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        },
        //判断订单是否过期
        getTimerState(order_time) {
            var timestamp = Date.parse(new Date()) / 1000;
            return timestamp - order_time
        },
        // 根据时间戳转换日期
        getNewDate(timer) {
            var timestr = new Date(timer)
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            return year + "." + (month < 10 ? '0' + month : month) + "." + (date < 10 ? '0' + date : date);
        }
    },
}