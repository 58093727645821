import notice from '../../../components/notice.vue' //须知
import lablePop from '../../../components/lablePop.vue' //弹窗
import couponItem from '../../../components/couponItem.vue' //优惠券
import { Calendar, Right } from "@icon-park/vue"; //icon图标
import ptBtnYq from "../../../components/ptBtn.vue"
export default {
    name: 'single',
    components: {
        notice,
        lablePop,
        couponItem,
        Calendar,
        Right,
        ptBtnYq
    },
    data() {
        return {
            infoData: {},
            pop_show: false,
            is_show: false,
            coupon_list: [],
            filter_type: 0, //筛选 0不选 1金额满减券,2次数增加劵
            select_id: null,
            is_null: false,
            is_login: null,
            formattedDate: '',
            select_data: null,
            is_pt: false,
            name: '',
            timer: ['00', '00', '00', '00'],
            is_zz: false,
			ticket_exp_date:[],
			attr_index:0
        }
    },
    created() {
        document.title = "门票详情";
        localStorage.removeItem('login_data');
        if (this.$route.query.mall_id) {
            localStorage.setItem('mall_id', this.$route.query.mall_id);
        };
        this.is_pt = this.$route.query.is_pt ? true : false;
        this.name = this.$route.query.name;
        this.getTicketInfo();
    },
    methods: {
        pt() {
            this.is_zz = true;
            this.$scaneMethod(
                location.href.split("#")[0],
                this.infoData.group_member[0].group_order.id
            );
        },
        toPf() {
            this.is_pt = 1;
        },
        hidePop(e) {
            this.pop_show = false;
        },
        setSelect(id) {
            this.select_id = id;
            this.select_data = null;
            this.coupon_list.forEach((item, index) => {
                if (item.id == this.select_id) {
                    this.select_data = item;
                }
            });
            console.log(this.select_data)
        },
        //获取门票详情
        getTicketInfo() {
            let _this = this;
            _this.$UtilsApi.ticketInfo(_this.$route.query.id, {}).then(res => {
                if (res.data.code == 0) {
                    _this.infoData = res.data.data;
                    this.formattedDate = _this.infoData.act_time_start;
					if (_this.infoData.type ==4){
						// 阶段渠道票
						this.ticket_exp_date = _this.infoData.expire_date.split("|");
						//this.ticket_exp_date = "2025-01-07|2025-01-09".split("|");
					}
                    _this.$UtilsApi.coupon_my({
                        status: 'unuse',
                        page: 0
                    }).then(new_res => {
                        _this.is_login = new_res;
                        if (new_res.data.code == 0) {
                            let arr = [];
                            _this.coupon_list = new_res.data.data.filter(item => item.info.ranges.indexOf(_this.infoData.type) != -1);
                            _this.coupon_list.forEach((item, index) => {
								if (item.info.activity_id == _this.infoData.activity_id){
									if (item.info.type == 1 && _this.infoData.price >= item.info.usage_limit && _this.infoData.price <= item.info.usage_limit_upper) {
										arr.push(item);
									}
									if (item.info.type == 2 && _this.infoData.num >= item.info.usage_limit && _this.infoData.num <= item.info.usage_limit_upper) {
										arr.push(item);
									}
								}
                            });
                            arr.length == 0 ? _this.is_null = true : '';
                            _this.coupon_list = arr;

                            function countdown(pay_time, work_time) {
                                // 计算结束时间戳（单位：毫秒）
                                var end_time = new Date(pay_time + work_time * 60 * 60 * 1000).getTime();

                                // 获取当前时间戳
                                var current_time = new Date().getTime();

                                // 计算剩余时间（单位：毫秒）
                                var remaining_time = end_time - current_time;

                                // 如果剩余时间小于等于0，表示已经过了结束时间
                                if (remaining_time <= 0) {
                                    return [0, 0, 0, 0]; // 返回全为0的数组表示倒计时结束
                                }

                                // 计算时、分、秒、毫秒
                                var hours = Math.floor(remaining_time / (1000 * 60 * 60));
                                var minutes = Math.floor((remaining_time % (1000 * 60 * 60)) / (1000 * 60));
                                var seconds = Math.floor((remaining_time % (1000 * 60)) / 1000);
                                var milliseconds = remaining_time % 1000;

                                // 返回时、分、秒、毫秒的数组
                                _this.timer = [hours < 10 ? '0' + hours : hours, minutes < 10 ? '0' + minutes : minutes, seconds < 10 ? '0' + seconds : seconds, Math.floor(milliseconds / 100) < 10 ? '0' + Math.floor(milliseconds / 100) : Math.floor(milliseconds / 100)];
                                // 每隔10毫秒更新倒计时显示
                                setTimeout(function() {
                                    countdown(_this.infoData.group_member[0].group_order.pay_time * 1000, _this.infoData.group_cnf.work_time);
                                }, 100);
                            };
							if (_this.infoData.group_member !== undefined){
								countdown(_this.infoData.group_member[0].group_order.pay_time * 1000, _this.infoData.group_cnf.work_time);
							}
                        }
                    });
                } else {
                    _this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        },
        gotoPay() {
            let if_mf = false;
            if (this.select_data) {
                // 0元购
                if (this.select_data.id == this.select_id && this.select_data.info.type == 1 && this.infoData.price - this.select_data.info.money <= 0) {
                    if_mf = true;
                }
            }
            if (if_mf) {
                console.log("满足0元购条件");
                this.createOrder();
            } else {
                console.log("不满足0元购条件");
                let data = {
                    type: this.infoData.type == 1 ? 'single' : 'free',
                    id: this.$route.query.id,
                    coupon_id: this.select_id
                };
                this.is_pt ? data.is_pt = 1 : '';
                this.is_pt ? data.group_ticket_id = this.infoData.group_cnf.id : '';
                this.$router.push({
                    name: 'info/pay',
                    query: data
                });
            }

        },
        //创建订单
        createOrder() {
            let data = {
                ticket_id: this.$route.query.id,
            };
            this.select_id ? data.member_coupon_id = this.select_id : '';
            this.$UtilsApi.activeCheckout(data).then(res => {
                if (res.data.code == 0) {
                    this.$router.replace({
                        name: 'payState',
                        query: {
                            type: 'ok',
                            order_id: res.data.data.order_info.id,
                            price: res.data.data.order_info.price
                        }
                    });
                } else {
                    if (res.data.msg) {
                        this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                    }
                }
            });
        },
        //判断日期区间
        isNowInTimeRange(expire_time_start, expire_time_end) {
            const now = Date.now(); // 当前时间的时间戳

            if (now >= expire_time_start && now <= expire_time_end) {
                return true; // 当前时间在区间内
            } else {
                return false; // 当前时间不在区间内
            }
        },
        toPay() {
			let attr_id=this.infoData.attrs && this.infoData.attrs.length?this.infoData.attrs[this.attr_index].id:false;
			if(attr_id){
				localStorage.setItem('attr_id',attr_id)
			}
            if (this.is_login.data.code == 0) {
                if (this.coupon_list.length && !this.is_pt) {
                    this.is_show = true;
                } else {
                    this.gotoPay()
                }
            } else {
                if (this.is_login.data.code == 401) {
                    let data = {
                        name: 'single',
                        id: this.$route.query.id,
                    };
                    if (this.is_pt) {
                        data.is_pt = 1;
                    }
                    localStorage.setItem('login_data', JSON.stringify(data));
                    window.location.replace('//paradise.h5.airland100.com/#/login');
                    return
                };
                this.$notify({ type: 'warning', message: this.is_login.data.message, duration: 2000 });
            }
        }
    },
}