import { Calendar } from "@icon-park/vue"; //icon图标
export default {
    name: 'write',
    components: {
        Calendar
    },
    data() {
        return {
            list: [],
            type: ['售票系统', '美团', '抖音'],
            type_index: 0,
            option1: [
                { text: '售票系统', value: 0 },
                { text: '美团', value: 1 },
                { text: '抖音', value: 2 }
            ],
        }
    },
    watch: {
        type_index(val) {
            this.getList();
        }
    },
    created() {
        document.title = "核销记录";
        this.getList();
    },
    methods: {
		bd_click(data){
			console.log(data)
			this.$UtilsApi.managePrinter({
				ticket_use_id:data.ticket_use_id
			}).then(res => {
			    if (res.data.code == 0) {
			         this.$notify({ type: 'success', message: '打印成功', duration: 2000 });
			    } else {
			        this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
			    }
			})
		},
        getList() {
            if (localStorage.getItem('write_' + (this.type_index == 0 ? 'mr' : this.type_index == 1 ? 'mt' : 'dy') + localStorage.getItem('user_id'))) {
                this.list = JSON.parse(localStorage.getItem('write_' + (this.type_index == 0 ? 'mr' : this.type_index == 1 ? 'mt' : 'dy') + localStorage.getItem('user_id')));
            } else {
                this.list = [];
            }
            console.log(this.list)
        },
        // 根据时间戳转换日期
        getDate(timestr) {
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            return year + "-" + (month < 10 ? '0' + month : month) + "-" + (date < 10 ? '0' + date : date);
        },
        // 根据时间戳转换日期
        getDateTimer(time) {
            var timestr = new Date(parseInt(time) * 1000);
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            var hour = timestr.getHours();
            var minute = timestr.getMinutes();
            var second = timestr.getSeconds();
            return year + "-" + (month < 10 ? '0' + month : month) + "-" + (date < 10 ? '0' + date : date) + " " + (hour < 10 ? '0' + hour : hour) + ":" + (minute < 10 ? '0' + minute : minute) + ":" + (second < 10 ? '0' + second : second);
        }
    },
}