import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import Vant from 'vant';
import ConfApi from "./besa/api";
import UtilsApi from "./besa/utils";
import 'vant/lib/index.css';
import text_config from './text_config.js';
import {
	Notify,
	PullRefresh,
	Dialog
} from 'vant';

// 全局注册
Vue.use(Vant);
Vue.use(Notify);
Vue.use(PullRefresh);
Vue.use(Dialog);
const scale_num = document.documentElement.clientWidth / 375;
Vue.prototype._scale = ((num) => {
	return num * scale_num
})
Vue.prototype.$TextConfig=text_config
Vue.prototype.$UtilsApi = new UtilsApi.UtilsApis(new ConfApi.configApi());
Vue.prototype.$appId = 'wx33b205bb7e47ba1e';
//获取倒计时
Vue.prototype.$countdown = function() {
	// 获取当前时间对象
	var current_date = new Date();
	var current_timestamp = current_date.getTime() / 1000; // 将毫秒转换为秒

	// 获取今天的年、月、日
	var year = current_date.getFullYear();
	var month = current_date.getMonth() + 1; // 月份是从0开始的，需要加1
	var day = current_date.getDate();

	// 构建今天24点的时间对象
	var end_of_day = new Date(year, month - 1, day, 23, 59, 59); // 小时为23，分钟为59，秒钟为59

	// 计算剩余时间（单位：秒）
	var remaining_time = Math.floor((end_of_day.getTime() - current_date.getTime()) / 1000);

	if (remaining_time <= 0) {
		console.log("已超过当天的截止时间");
		return;
	}

	// 计算剩余小时、分钟和秒数
	var hours = Math.floor(remaining_time / 3600);
	var minutes = Math.floor((remaining_time % 3600) / 60);
	var seconds = remaining_time % 60;

	// 格式化输出
	var countdown_str = hours.toString().padStart(2, '0') + ":" +
		minutes.toString().padStart(2, '0') + ":" +
		seconds.toString().padStart(2, '0');

	return countdown_str;
};
//微信分享
Vue.prototype.$scaneMethod = function(href, id) {
	var _this = this;
	var ua = navigator.userAgent.toLowerCase();
	var isWeixin = ua.indexOf('micromessenger') !== -1;
	_this.mall_list = null;
	if (!isWeixin) {
		alert('请用微信打开连接');
		return
	}
	Vue.prototype.$UtilsApi.jsConfig({
		t_url: decodeURIComponent(href),
	}).then(res => {
		if (res.data.code == 0) {
			wx.config({
				debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
				appId: 'wx33b205bb7e47ba1e', // 必填，公众号的唯一标识
				timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
				nonceStr: res.data.data.nonce_str, // 必填，生成签名的随机串
				signature: res.data.data.signature, // 必填，签名
				jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'showMenuItems',
					'showOptionMenu'
				]
			});
			// 显示分享按钮
			wx.ready(function() {
				wx.showOptionMenu();
				wx.showMenuItems({
					menuList: ['menuItem:share:appMessage', 'menuItem:share:timeline']
				});
			});
			wx.ready(function() {
				wx.onMenuShareTimeline({
					title: '快闪“迪士尼”，空降无限快乐！',
					desc: (localStorage.getItem('fx_name') ? localStorage.getItem(
						'fx_name') : '') + '-哈尔堡快闪主题乐园门票福利来了，快来和我一起团票吧，就差你了！',
					link: 'https://paradise.h5.airland100.com/#/share?id=' + id +
						'&mall_id=' + localStorage.getItem('mall_id'),
					imgUrl: localStorage.getItem('fx_url') ? localStorage.getItem(
						'fx_url') :
						'https://paradise.api.airland100.com/upload/image/2024-01/11/659f4fab_YHSSFUMOTN.jpg',
					success: function() {
						console.log('分享到朋友圈成功');
					},
					cancel: function() {
						console.log('用户取消分享到朋友圈');
					}
				});

				wx.onMenuShareAppMessage({
					title: '快闪“迪士尼”，空降无限快乐',
					desc: (localStorage.getItem('fx_name') ? localStorage.getItem(
						'fx_name') : '') + '-哈尔堡快闪主题乐园门票福利来了，快来和我一起团票吧，就差你了！',
					link: 'https://paradise.h5.airland100.com/#/share?id=' + id +
						'&mall_id=' + localStorage.getItem('mall_id'),
					imgUrl: localStorage.getItem('fx_url') ? localStorage.getItem(
						'fx_url') :
						'https://paradise.api.airland100.com/upload/image/2024-01/11/659f4fab_YHSSFUMOTN.jpg',
					success: function() {
						console.log('分享给朋友成功');
					},
					cancel: function() {
						console.log('用户取消分享给朋友');
					}
				});
			});
			wx.error(function(res) {
				alert('配置失败', res);
				// config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
			});
		}
	});
}

Vue.config.productionTip = false;
new Vue({
	router,
	render: h => h(App)
}).$mount("#app")