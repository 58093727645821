<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  created() {
    let htmlSize = document.documentElement;
    htmlSize.style.fontSize = document.documentElement.clientWidth / 375 + "px";
  },
  methods: {},
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  font-size: 14rem;
}
.container {
  background: #f1f2eb;
  left: 0;
  top: 0;
}
.suo_b {
  font-size: 10rem !important;
}
/* 待支付 */
/deep/.order_color1 {
  color: #ffb230 !important;
}
/* 可使用 */
/deep/.order_color2 {
  color: #48be80 !important;
}
/* 退款中 */
/deep/.order_color3 {
  color: #e7140a !important;
}
/* 已退款 */
/deep/.order_color4 {
  color: #e7140a !important;
}
/deep/.attr_box{
	color: #09abfd;
	font-size: 12rem;
}
/deep/.totalPrice{
	display: flex;
	align-items: center;
}
</style>
