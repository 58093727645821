//默认注意事项
const notice_default = {
	"温馨提示": [
		'·进场人员必须穿防滑袜',
		'·禁止携带易燃、易爆、打火机及管制器具等危险品入场',
		'·场地内禁止吸烟、禁止饮食，请勿乱扔垃圾'
	],
	"适宜人群": [
		'·本乐园适宜3岁以上儿童游玩，3-8岁儿童必须家长陪同进入，60岁以上老人及孕妇慎入',
		'·园内主滑梯适合5岁以上儿童使用',
		'·患有传染病、心脏病、心脑血管疾病、饮酒者及身体不适者禁止进入场地'
	],
	"使用规则": [
		'·门票需在活动有效期内使用',
		'·入场请兑换手环，门票不作为入场凭证',
		'·门票仅限本人使用，禁止转借、转增他人，或与他人共用'
	],
	"timer": {
		"title":'游玩时间',
		"info":[
			"全天可用，如遇高峰时间，现场将进行安全限流。"
		]
	}
}

const titles = {
	1: "哈尔堡",
	2: "哈尔堡",
	3: "ALL-SPACE"
}

const notices = {
	1: notice_default,
	2: notice_default,
	3: {
		"温馨提示": [
			'1.票品为有价观演凭证，非普通商品，其背后承载的文化服务具有时效性、唯一性等特征，如非演出变更、演出取消、票品错误的原因，不提供退票服务，购票时请务必仔细核对并谨慎下单。',
			'2.实际体验片源以门店实际排期为准。',
			'3.禁止携带物品:由于安保和版权的原因，XR体验区内非受邀禁止携带专业摄影摄像器材,禁止录音摄影摄像、禁止食用食品饮料,禁止携带易燃易爆危险品。',
			'4.请将随身携带的贵重物品放入存包柜。',
			'5.为了保证体验效果，体验期间请低声交谈，场内严禁跑动，大声喧哗。'
		],
		"适宜人群": [
			'1.本馆头显设备重量为0.7kg，会对儿童观众造成一定程度的身体负担，6 岁以下儿童谢绝入场，6-12 岁儿童、60岁以上老人，须在监护人陪同下观展，18岁以上需买成人票。',
			'2.为了确保您的安全与健康，对于 3D 视觉眩晕、幽闭恐惧症等人士不建议体验，如果您有心脏疾病、高血压等健康问题请避免参与体验，孕妇请避免参与体验，感谢您的理解和支持。'
		],
		"使用规则": [
			'1.单次消费不限制使用张数 ，不与店内优惠同享。',
			'2.门票需在活动有效期内使用，单人门票仅限一成人使用，亲子双人票仅限一成人一儿童使用，亲子双人票支持两成人一儿童或者一成人两儿童使用。',
			'3.如您购买的门票为工作日门票，则该门票可以在周一至周五使用，如您购买的门票为节假日门票，则该门票可以在周六、周日以及节假日使用。',
			'4.每场次支持单人或多人组队体验，组队体验人数每组最多不超过6人。',
			'5.到店服务台展示门票二维码换取纸质门票，门票盖章生效；门纸质为唯一入场凭证，丢失不补视为使用，请妥善保管。',
			'6.如有其他问题，可咨询门店电话或现场工作人员。'
		],
		"timer": {
			"title":'体验时间',
			"info":[
				"约25-40分钟，具体以片源为准。"
			]
		}
	}
}

export default {
	titles,
	notices
}